<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- title="Gestion des Ressources Humaines" -->
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>Liste des Juristes</span>
          </template>

          <b-card-text>
            <!-- Table Container Card -->
            <b-card no-body class="mb-0">
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="4"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pull-left"
                  >
                    <label>Afficher</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>Elements par page</label>
                  </b-col>

                  <!-- Search -->
                  <b-col cols="12" md="8">
                    <div
                      class="d-flex align-items-center justify-content-end pull-right"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Rechercher..."
                      />
                      <b-button
                        variant="primary"
                        :to="{ name: 'attendance-add-new' }"
                      >
                        <!-- @click="isAddNewDataSidebarActive = true" -->
                        <span class="text-nowrap">Enregistrer Suivi</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <b-table
                id="tableFicheAttendance"
                ref="refDataListTable"
                class="position-relative"
                :items="fetchModuleAttendances"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="Aucun resultat trouvé"
                :sort-desc.sync="isSortDirDesc"
              >
                <template #cell(direction)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :icon="resolveAttendanceIcon(data.item.direction)"
                      size="18"
                      class="mr-50"
                      :class="`text-${resolveAttendanceVariant(
                        data.item.direction
                      )}`"
                    />
                    <span class="align-text-top text-capitalize">{{
                      data.item.direction
                    }}</span>
                  </div>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveDataStatusVariant(
                      data.item.status
                    )}`"
                    class="text-capitalize"
                  >
                    {{ data.item.status }}
                  </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`attendance-row-${data.item.id}-preview-icon`"
                      icon="EyeIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'attendance-view',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="Visionner Attendance"
                      :target="`attendance-row-${data.item.id}-preview-icon`"
                    />
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted"
                      >Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
                      {{ dataMeta.of }} Enregistements</span
                    >
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalDatas"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-card-text>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="FileIcon" />
            <span>Fiche de Attendance</span>
          </template>
          <b-card-text>
            <b-row class="container">
              <b-col cols="12" md="2">
                <!-- month -->
                <b-form-group label="Année" label-for="year">
                  <b-form-input
                    id="year"
                    v-model="attendanceData.year"
                    trim
                    class=""
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="2">
                <!-- month -->
                <b-form-group label="Mois" label-for="type">
                  <v-select
                    v-model="attendanceData.month"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="monthOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="month"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <!-- agent -->
                <b-form-group label="Agent" label-for="sku_agent">
                  <v-select
                    v-model="attendanceData.sku_agent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="agentOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="sku_agent"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6" md="2">
                <!-- agent -->
                <b-form-group label="sku_agent" label-for="sku_agent">
                  <b-button variant="primary" @click="loadAttendanceData">
                    <span class="text-nowrap">Recupérer Fiche</span>
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="2">
                <!-- agent -->
                <b-form-group label="sku_agent" label-for="sku_agent">
                  <b-button variant="success" @click="printAttendanceFile">
                    <span class="text-nowrap">Imprimer</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table-simple
              hover
              caption-top
              responsive
              class="rounded-bottom mb-0"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th colspan="3"> Jours Feriés </b-th>
                  <b-th colspan="3"> Clothes </b-th>
                  <b-th colspan="2"> Accessories </b-th>
                </b-tr>
                <b-tr>
                  <b-th>Jour</b-th>
                  <b-th>Date</b-th>
                  <b-th>Heure Debut</b-th>
                  <b-th>Heure Fin</b-th>
                  <b-th>Signature</b-th>
                  <b-th>Jrs Travailés</b-th>
                  <b-th>Commentaires</b-th>
                  <b-th>Notes du Superviseur</b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="attendanceDataLoaded == true">
                <b-tr v-for="(item, jour) in ficheAttendance" :key="jour">
                  <!-- <b-th rowspan="3"> {{ item.jour }}  </b-th> -->
                  <!-- <b-td variant="success"> 72 </b-td> -->
                  <b-th class="text-right">
                    <span v-if="!item.weekend"> {{ item.jour }}</span>
                    <span v-else>-</span>
                  </b-th>
                  <b-td :variant="`${item.weekend ? 'warning' : ''}`">{{
                    item.date
                  }}</b-td>
                  <b-td>{{ item.type }}</b-td>
                  <b-td>{{ item.direction }}</b-td>
                  <b-td>{{ item.weekend }}</b-td>
                  <b-td>{{ item.weekend }}</b-td>
                </b-tr>
                <!-- <b-tr>
                  <b-th class="text-right"> Gent </b-th>
                  <b-td>46</b-td>
                  <b-td variant="warning"> 18 </b-td>
                  <b-td>50</b-td>
                  <b-td>61</b-td>
                  <b-td>01</b-td>
                  <b-td variant="danger"> 15 </b-td>
                </b-tr>
                <b-tr>
                  <b-th class="text-right"> Brussels </b-th>
                  <b-td>51</b-td>
                  <b-td>27</b-td>
                  <b-td>28</b-td>
                  <b-td>38</b-td>
                  <b-td>69</b-td>
                  <b-td>28</b-td>
                </b-tr>
                <b-tr>
                  <b-th rowspan="2"> The Netherlands </b-th>
                  <b-th class="text-right"> Amsterdam </b-th>
                  <b-td variant="success"> 89 </b-td>
                  <b-td>34</b-td>
                  <b-td>69</b-td>
                  <b-td>85</b-td>
                  <b-td>38</b-td>
                </b-tr>
                <b-tr>
                  <b-th class="text-right"> Utrecht </b-th>
                  <b-td>80</b-td>
                  <b-td variant="danger"> 12 </b-td>
                  <b-td>43</b-td>
                  <b-td>36</b-td>
                  <b-td>50</b-td>
                  <b-td variant="warning"> 19 </b-td>
                </b-tr> -->
              </b-tbody>
              <b-tbody v-if="attendanceDataLoaded == false">
                <b-tr aria-colspan="3" rowspan="2" class="text-warning">
                  <b-td>Attente de selection...</b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr>
                  <b-td colspan="8" variant="secondary" class="text-right">
                    Jours Total: <b>5</b>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </b-card-text>
          <!-- <b-card-text
            >Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum
            bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer
            caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake
            candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate
            cake gummi bears.</b-card-text
          > -->
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="BarChartIcon" />
            <span>Evaluation de Performance</span>
          </template>
          <b-card-text>
            Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake
            pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate
            marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert
            croissant chocolate jujubes fruitcake. Pie cupcake halvah.
          </b-card-text>
          <b-card-text>
            <!-- Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum
            bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer
            caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake
            candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate
            cake gummi bears. -->
          </b-card-text>
        </b-tab>
        <!-- <b-tab title="Disabled" disabled>
          <b-card-text> Carrot cake dragée chocolate. </b-card-text>
        </b-tab> -->
      </b-tabs>

      <template #code>
        {{ codeDefault }}
      </template>
    </b-card>

    <!-- Filters -->
    <!-- <list-data-table-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";
//table
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";

// sweet alert
import Ripple from "vue-ripple-directive";
import { codeBasic } from "../../extensions/sweet-alert/code";
//
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeIcon } from "../../extensions/toastification/code";

// tabs
import TabDefault from "./TabDefault.vue";
import BCardCode from "@core/components/b-card-code";
import { BForm, BFormGroup, BTabs, BTab, BCardText } from "bootstrap-vue";
import { codeDefault } from "./code";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    ListDataTableFilters,
    // ListDataTable, //

    BCard,
    BRow,
    BCol,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    //tabs
    TabDefault,
    BCardCode,
    BTabs,
    BCardText,
    BTab,
    // table
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  data() {
    return {
      codeIcon,
      attendanceData: {},
      monthOptions: [],
      agentOptions: [],
      ficheAttendance: [],
      attendanceDataLoaded: false,
    };
  },
  directives: {
    Ripple,
  },
  props: {},
  async mounted() {
    // year
    this.attendanceData.year = new Date().getFullYear();
    // month options
    this.monthOptions.push({ label: "Janvier", value: "01" });
    this.monthOptions.push({ label: "Février", value: "02" });
    this.monthOptions.push({ label: "Mars", value: "03" });
    this.monthOptions.push({ label: "Avril", value: "04" });
    this.monthOptions.push({ label: "Mai", value: "05" });
    this.monthOptions.push({ label: "Juin", value: "06" });
    this.monthOptions.push({ label: "Juillet", value: "07" });
    this.monthOptions.push({ label: "Aout", value: "08" });
    this.monthOptions.push({ label: "Septembre", value: "09" });
    this.monthOptions.push({ label: "Octobre", value: "10" });
    this.monthOptions.push({ label: "Novembre", value: "11" });
    this.monthOptions.push({ label: "Décembre", value: "12" });

    // load agents list
    await axios
      .get(`agent`)
      .then((response) => {
        // default action
        var data = response.data.agents;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.agentOptions.push({
            label:
              data[i].firstname +
              " " +
              data[i].lastname +
              " " +
              data[i].surname,
            value: data[i].sku_agent,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  methods: {
    printAttendanceFile() {
      window.print();
    },
    async loadAttendanceData() {
      // load agents list
      this.attendanceDataLoaded = false;
      await axios
        .get(
          $themeConfig.app.api_endpoint +
            `get_attendance_file/${$themeConfig.app.api_endpoint_access_token}`,
          {
            params: this.attendanceData,
          }
        )
        .then((response) => {
          // default action
          this.ficheAttendance = JSON.parse(JSON.stringify(response.data.data));
          console.log(this.ficheAttendance.length);
          console.log(this.ficheAttendance);
          // var i = 0;
          // for (i = 0; i < data.length; i++) {}
          this.attendanceDataLoaded = true;
        })
        .catch((error) => {
          //
          this.attendanceDataLoaded = true;
          console.log(error);
        });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-attendance";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const isAddNewDataSidebarActive = ref(false);

    const typeOptions = [
      { label: "Matière Première", value: "first-way" },
      { label: "Produit Semi-Fini", value: "half-finished" },
      { label: "Produit Fini", value: "finished" },
      { label: "Produit Dérivé", value: "derived" },
      { label: "Consommables", value: "consummable" },
      { label: "Service", value: "service" },
      { label: "Autres", value: "other" },
    ];

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const resolveAttendanceVariant = (direction) => {
      if (direction === "in") return "primary";
      if (direction === "out") return "warning";
      return "primary";
    };

    const resolveAttendanceIcon = (direction) => {
      if (direction === "in") return "ArrowDownIcon";
      if (direction === "out") return "ArrowUpIcon";
      return "UserIcon";
    };

    const {
      fetchModuleAttendances,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      // Extra Filters
      typeFilter,
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModuleAttendances,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveAttendanceIcon,
      resolveAttendanceVariant,
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      typeOptions,
      statusOptions,

      // Extra Filters
      typeFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
